/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const customGenericMailSender = /* GraphQL */ `query CustomGenericMailSender(
  $recipientMails: [String]
  $mailSubject: String
  $mailBody: String
  $ccMails: [String]
  $bccMails: [String]
  $replyAddresses: [String]
) {
  customGenericMailSender(
    recipientMails: $recipientMails
    mailSubject: $mailSubject
    mailBody: $mailBody
    ccMails: $ccMails
    bccMails: $bccMails
    replyAddresses: $replyAddresses
  )
}
` as GeneratedQuery<
  APITypes.CustomGenericMailSenderQueryVariables,
  APITypes.CustomGenericMailSenderQuery
>;
export const GetExchangeRates = /* GraphQL */ `query GetExchangeRates($sourceCurrency: String, $destinationCurrency: String) {
  GetExchangeRates(
    sourceCurrency: $sourceCurrency
    destinationCurrency: $destinationCurrency
  ) {
    exchangeRate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExchangeRatesQueryVariables,
  APITypes.GetExchangeRatesQuery
>;
export const GetSigningUrl = /* GraphQL */ `query GetSigningUrl(
  $signature: String
  $investmentID: String
  $type: AgreementTypes
  $userIP: String
) {
  GetSigningUrl(
    signature: $signature
    investmentID: $investmentID
    type: $type
    userIP: $userIP
  ) {
    signatureUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSigningUrlQueryVariables,
  APITypes.GetSigningUrlQuery
>;
export const GetAllPaymentsHistory = /* GraphQL */ `query GetAllPaymentsHistory($options: AWSJSON) {
  GetAllPaymentsHistory(options: $options)
}
` as GeneratedQuery<
  APITypes.GetAllPaymentsHistoryQueryVariables,
  APITypes.GetAllPaymentsHistoryQuery
>;
export const GetUserAccountStatement = /* GraphQL */ `query GetUserAccountStatement($options: AWSJSON) {
  GetUserAccountStatement(options: $options)
}
` as GeneratedQuery<
  APITypes.GetUserAccountStatementQueryVariables,
  APITypes.GetUserAccountStatementQuery
>;
export const EndInvestment = /* GraphQL */ `query EndInvestment($params: AWSJSON) {
  EndInvestment(params: $params)
}
` as GeneratedQuery<
  APITypes.EndInvestmentQueryVariables,
  APITypes.EndInvestmentQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    nationality
    hearAboutUs
    referral
    taxId
    taxIdType
    country
    state
    city
    street
    postalCode
    legalAgreements
    usAccreditedInvestor {
      annualIncome
      netWorth
      includesSpouse
      accreditedInvestorCondition
      accreditedInvestorFlag
      accreditedInvestorExemption
      __typename
    }
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    identification {
      idType
      idNumber
      taxIdType
      taxId
      __typename
    }
    kycDetails {
      type
      identificationNumber
      issuingCountry
      issueDate
      expirationDate
      reason
      verificationId
      documentPhotos
      verifiedDelivered
      rejectedDelivered
      __typename
    }
    Documents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    minimumInvestment
    minimumDeposit
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    withdrawals {
      nextToken
      __typename
    }
    loans {
      nextToken
      __typename
    }
    investments {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    clientId
    accountId
    wallets {
      __typename
    }
    otpCode {
      code
      timestamp
      __typename
    }
    onboardingStatus
    kycVerificationStatus
    monthlyInterestPayoutsOptIn
    migrationStatus
    isArchived
    QbVendorId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getDocuments = /* GraphQL */ `query GetDocuments($id: ID!) {
  getDocuments(id: $id) {
    id
    key
    bucketName
    fileName
    name
    category
    entityType
    entityId
    User {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentsQueryVariables,
  APITypes.GetDocumentsQuery
>;
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $filter: ModelDocumentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      key
      bucketName
      fileName
      name
      category
      entityType
      entityId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
export const documentsByUser = /* GraphQL */ `query DocumentsByUser(
  $entityId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentsFilterInput
  $limit: Int
  $nextToken: String
) {
  documentsByUser(
    entityId: $entityId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      key
      bucketName
      fileName
      name
      category
      entityType
      entityId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DocumentsByUserQueryVariables,
  APITypes.DocumentsByUserQuery
>;
export const getLoan = /* GraphQL */ `query GetLoan($id: ID!) {
  getLoan(id: $id) {
    id
    startDate
    endDate
    amount
    currency
    coverImage
    sector
    title
    country
    countryFlag
    textOverview
    textUseOfFunds
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLoanQueryVariables, APITypes.GetLoanQuery>;
export const listLoans = /* GraphQL */ `query ListLoans(
  $filter: ModelLoanFilterInput
  $limit: Int
  $nextToken: String
) {
  listLoans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      amount
      currency
      coverImage
      sector
      title
      country
      countryFlag
      textOverview
      textUseOfFunds
      Status
      createdAt
      updatedAt
      userID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLoansQueryVariables, APITypes.ListLoansQuery>;
export const getLoansByUser = /* GraphQL */ `query GetLoansByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLoanFilterInput
  $limit: Int
  $nextToken: String
) {
  getLoansByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      startDate
      endDate
      amount
      currency
      coverImage
      sector
      title
      country
      countryFlag
      textOverview
      textUseOfFunds
      Status
      createdAt
      updatedAt
      userID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLoansByUserQueryVariables,
  APITypes.GetLoansByUserQuery
>;
export const getWithdrawals = /* GraphQL */ `query GetWithdrawals($id: ID!) {
  getWithdrawals(id: $id) {
    id
    userID
    amount
    currency
    datePaidOut
    createdAt
    updatedAt
    withdrawalStatus
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWithdrawalsQueryVariables,
  APITypes.GetWithdrawalsQuery
>;
export const listWithdrawals = /* GraphQL */ `query ListWithdrawals(
  $filter: ModelWithdrawalsFilterInput
  $limit: Int
  $nextToken: String
) {
  listWithdrawals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      amount
      currency
      datePaidOut
      createdAt
      updatedAt
      withdrawalStatus
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWithdrawalsQueryVariables,
  APITypes.ListWithdrawalsQuery
>;
export const getWithdrawalsByUser = /* GraphQL */ `query GetWithdrawalsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWithdrawalsFilterInput
  $limit: Int
  $nextToken: String
) {
  getWithdrawalsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      amount
      currency
      datePaidOut
      createdAt
      updatedAt
      withdrawalStatus
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWithdrawalsByUserQueryVariables,
  APITypes.GetWithdrawalsByUserQuery
>;
export const getInvestment = /* GraphQL */ `query GetInvestment($id: ID!) {
  getInvestment(id: $id) {
    id
    startDate
    endDate
    actualEndDate
    annualInterestRate
    renewFlag
    renewOption
    currency
    principleAmount
    InterestCycleDays
    tenorDays
    expectedTotalInterest
    expectedInterestPerInterestCycle
    expectedNumberOfInterestCycles
    interestPayoutOption
    funded
    fundedAt
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    payments {
      nextToken
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    references {
      nextToken
      __typename
    }
    fxTransactions {
      nextToken
      __typename
    }
    investmentAgreement {
      signed
      signature
      signedAgreementUrl
      signedAt
      __typename
    }
    isBackdated
    nextInterestPerInterestCycle
    isRenewed
    investmentStep
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvestmentQueryVariables,
  APITypes.GetInvestmentQuery
>;
export const listInvestments = /* GraphQL */ `query ListInvestments(
  $filter: ModelInvestmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvestments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvestmentsQueryVariables,
  APITypes.ListInvestmentsQuery
>;
export const getInvestmentsByUser = /* GraphQL */ `query GetInvestmentsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvestmentFilterInput
  $limit: Int
  $nextToken: String
) {
  getInvestmentsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvestmentsByUserQueryVariables,
  APITypes.GetInvestmentsByUserQuery
>;
export const getPayments = /* GraphQL */ `query GetPayments($id: ID!) {
  getPayments(id: $id) {
    id
    paymentCategory
    paymentType
    currency
    amount
    expectedDate
    actualDate
    initiatedBy
    notes
    Status
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    monthlyInterestBankPayouts {
      nextToken
      __typename
    }
    dueInterestBankPayouts {
      nextToken
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentsQueryVariables,
  APITypes.GetPaymentsQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelPaymentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
export const paymentsByExpectedDate = /* GraphQL */ `query PaymentsByExpectedDate(
  $expectedDate: AWSDateTime!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentsFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentsByExpectedDate(
    expectedDate: $expectedDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentsByExpectedDateQueryVariables,
  APITypes.PaymentsByExpectedDateQuery
>;
export const getPaymentsByUser = /* GraphQL */ `query GetPaymentsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentsFilterInput
  $limit: Int
  $nextToken: String
) {
  getPaymentsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentsByUserQueryVariables,
  APITypes.GetPaymentsByUserQuery
>;
export const getPaymentsByInvestment = /* GraphQL */ `query GetPaymentsByInvestment(
  $investmentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentsFilterInput
  $limit: Int
  $nextToken: String
) {
  getPaymentsByInvestment(
    investmentID: $investmentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentsByInvestmentQueryVariables,
  APITypes.GetPaymentsByInvestmentQuery
>;
export const getMonthlyInterestBankPayouts = /* GraphQL */ `query GetMonthlyInterestBankPayouts($id: ID!) {
  getMonthlyInterestBankPayouts(id: $id) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMonthlyInterestBankPayoutsQueryVariables,
  APITypes.GetMonthlyInterestBankPayoutsQuery
>;
export const listMonthlyInterestBankPayouts = /* GraphQL */ `query ListMonthlyInterestBankPayouts(
  $filter: ModelMonthlyInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMonthlyInterestBankPayouts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMonthlyInterestBankPayoutsQueryVariables,
  APITypes.ListMonthlyInterestBankPayoutsQuery
>;
export const getMonthlyInterestPayoutsByUser = /* GraphQL */ `query GetMonthlyInterestPayoutsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMonthlyInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getMonthlyInterestPayoutsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMonthlyInterestPayoutsByUserQueryVariables,
  APITypes.GetMonthlyInterestPayoutsByUserQuery
>;
export const getMonthlyPayoutsByInvestment = /* GraphQL */ `query GetMonthlyPayoutsByInvestment(
  $investmentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMonthlyInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getMonthlyPayoutsByInvestment(
    investmentID: $investmentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMonthlyPayoutsByInvestmentQueryVariables,
  APITypes.GetMonthlyPayoutsByInvestmentQuery
>;
export const getMonthlyPayoutsByPayment = /* GraphQL */ `query GetMonthlyPayoutsByPayment(
  $paymentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMonthlyInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getMonthlyPayoutsByPayment(
    paymentID: $paymentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMonthlyPayoutsByPaymentQueryVariables,
  APITypes.GetMonthlyPayoutsByPaymentQuery
>;
export const getDueInterestBankPayouts = /* GraphQL */ `query GetDueInterestBankPayouts($id: ID!) {
  getDueInterestBankPayouts(id: $id) {
    id
    amount
    currency
    firstName
    lastName
    email
    phoneNumber
    country
    bankAccountPayoutUSD {
      accountName
      accountType
      accountNumber
      routingType
      routingNumber
      bankName
      iban
      swiftCode
      bankPostalCode
      bankCountry
      country
      achRoutingNumber
      wireRoutingNumber
      currency
      bankAddress
      recipientCountry
      recipientAddress
      memo
      __typename
    }
    bankAccountPayoutNGN {
      accountName
      accountType
      accountNumber
      country
      achRoutingNumber
      bankName
      iban
      bankPostalCode
      recipientCountry
      currency
      bankAddress
      recipientAddress
      memo
      __typename
    }
    walletPayinDate
    dueBankPayoutDate
    payoutStatus
    paymentCategory
    createdAt
    updatedAt
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    paymentID
    payment {
      id
      paymentCategory
      paymentType
      currency
      amount
      expectedDate
      actualDate
      initiatedBy
      notes
      Status
      createdAt
      updatedAt
      userID
      investmentID
      owner
      __typename
    }
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDueInterestBankPayoutsQueryVariables,
  APITypes.GetDueInterestBankPayoutsQuery
>;
export const listDueInterestBankPayouts = /* GraphQL */ `query ListDueInterestBankPayouts(
  $filter: ModelDueInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDueInterestBankPayouts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDueInterestBankPayoutsQueryVariables,
  APITypes.ListDueInterestBankPayoutsQuery
>;
export const getDueInterestBankPayoutsByUser = /* GraphQL */ `query GetDueInterestBankPayoutsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDueInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getDueInterestBankPayoutsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDueInterestBankPayoutsByUserQueryVariables,
  APITypes.GetDueInterestBankPayoutsByUserQuery
>;
export const getDueInterestBankPayoutsByInvestment = /* GraphQL */ `query GetDueInterestBankPayoutsByInvestment(
  $investmentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDueInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getDueInterestBankPayoutsByInvestment(
    investmentID: $investmentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDueInterestBankPayoutsByInvestmentQueryVariables,
  APITypes.GetDueInterestBankPayoutsByInvestmentQuery
>;
export const getDueInterestBankPayoutsByPayment = /* GraphQL */ `query GetDueInterestBankPayoutsByPayment(
  $paymentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDueInterestBankPayoutsFilterInput
  $limit: Int
  $nextToken: String
) {
  getDueInterestBankPayoutsByPayment(
    paymentID: $paymentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      currency
      firstName
      lastName
      email
      phoneNumber
      country
      walletPayinDate
      dueBankPayoutDate
      payoutStatus
      paymentCategory
      createdAt
      updatedAt
      userID
      investmentID
      paymentID
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDueInterestBankPayoutsByPaymentQueryVariables,
  APITypes.GetDueInterestBankPayoutsByPaymentQuery
>;
export const getReferences = /* GraphQL */ `query GetReferences($id: ID!) {
  getReferences(id: $id) {
    id
    reference
    amount
    currency
    Status
    ProofOfPaymentUrl
    notes
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    createdAt
    updatedAt
    receivedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferencesQueryVariables,
  APITypes.GetReferencesQuery
>;
export const listReferences = /* GraphQL */ `query ListReferences(
  $filter: ModelReferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reference
      amount
      currency
      Status
      ProofOfPaymentUrl
      notes
      investmentID
      userID
      createdAt
      updatedAt
      receivedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferencesQueryVariables,
  APITypes.ListReferencesQuery
>;
export const getInvestmentByReference = /* GraphQL */ `query GetInvestmentByReference(
  $investmentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  getInvestmentByReference(
    investmentID: $investmentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reference
      amount
      currency
      Status
      ProofOfPaymentUrl
      notes
      investmentID
      userID
      createdAt
      updatedAt
      receivedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvestmentByReferenceQueryVariables,
  APITypes.GetInvestmentByReferenceQuery
>;
export const getReferencesByUser = /* GraphQL */ `query GetReferencesByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  getReferencesByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reference
      amount
      currency
      Status
      ProofOfPaymentUrl
      notes
      investmentID
      userID
      createdAt
      updatedAt
      receivedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferencesByUserQueryVariables,
  APITypes.GetReferencesByUserQuery
>;
export const getFxTransactions = /* GraphQL */ `query GetFxTransactions($id: ID!) {
  getFxTransactions(id: $id) {
    id
    fxRate
    fromCurrency
    fromAmount
    toCurrency
    toAmount
    currencyPair
    fee
    buyPrice
    sellPrice
    Status
    ProofOfPaymentUrl
    userReference
    convertFromWallet
    investmentID
    investment {
      id
      startDate
      endDate
      actualEndDate
      annualInterestRate
      renewFlag
      renewOption
      currency
      principleAmount
      InterestCycleDays
      tenorDays
      expectedTotalInterest
      expectedInterestPerInterestCycle
      expectedNumberOfInterestCycles
      interestPayoutOption
      funded
      fundedAt
      Status
      createdAt
      updatedAt
      userID
      isBackdated
      nextInterestPerInterestCycle
      isRenewed
      investmentStep
      owner
      __typename
    }
    userID
    user {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      nationality
      hearAboutUs
      referral
      taxId
      taxIdType
      country
      state
      city
      street
      postalCode
      legalAgreements
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      clientId
      accountId
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      migrationStatus
      isArchived
      QbVendorId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFxTransactionsQueryVariables,
  APITypes.GetFxTransactionsQuery
>;
export const listFxTransactions = /* GraphQL */ `query ListFxTransactions(
  $filter: ModelFxTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFxTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fxRate
      fromCurrency
      fromAmount
      toCurrency
      toAmount
      currencyPair
      fee
      buyPrice
      sellPrice
      Status
      ProofOfPaymentUrl
      userReference
      convertFromWallet
      investmentID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFxTransactionsQueryVariables,
  APITypes.ListFxTransactionsQuery
>;
export const getInvestmentByFxTransactions = /* GraphQL */ `query GetInvestmentByFxTransactions(
  $investmentID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFxTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  getInvestmentByFxTransactions(
    investmentID: $investmentID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fxRate
      fromCurrency
      fromAmount
      toCurrency
      toAmount
      currencyPair
      fee
      buyPrice
      sellPrice
      Status
      ProofOfPaymentUrl
      userReference
      convertFromWallet
      investmentID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvestmentByFxTransactionsQueryVariables,
  APITypes.GetInvestmentByFxTransactionsQuery
>;
export const getFxTransactionsByUser = /* GraphQL */ `query GetFxTransactionsByUser(
  $userID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFxTransactionsFilterInput
  $limit: Int
  $nextToken: String
) {
  getFxTransactionsByUser(
    userID: $userID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fxRate
      fromCurrency
      fromAmount
      toCurrency
      toAmount
      currencyPair
      fee
      buyPrice
      sellPrice
      Status
      ProofOfPaymentUrl
      userReference
      convertFromWallet
      investmentID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFxTransactionsByUserQueryVariables,
  APITypes.GetFxTransactionsByUserQuery
>;
export const getPlatformMetrics = /* GraphQL */ `query GetPlatformMetrics($id: ID!) {
  getPlatformMetrics(id: $id) {
    id
    data {
      createdAt
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlatformMetricsQueryVariables,
  APITypes.GetPlatformMetricsQuery
>;
export const listPlatformMetrics = /* GraphQL */ `query ListPlatformMetrics(
  $filter: ModelPlatformMetricsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlatformMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlatformMetricsQueryVariables,
  APITypes.ListPlatformMetricsQuery
>;
export const encodeToken = /* GraphQL */ `query EncodeToken($value: String!, $secret: String!) {
  encodeToken(value: $value, secret: $secret)
}
` as GeneratedQuery<
  APITypes.EncodeTokenQueryVariables,
  APITypes.EncodeTokenQuery
>;
export const GetUserBalances = /* GraphQL */ `query GetUserBalances($uid: String, $accountId: String) {
  GetUserBalances(uid: $uid, accountId: $accountId) {
    walletBalance {
      USD
      NGN
      __typename
    }
    activeInvestment {
      USD
      NGN
      __typename
    }
    totalBalance {
      USD
      NGN
      __typename
    }
    lifetimeInvestments {
      USD
      NGN
      __typename
    }
    lifetimeInterestEarned {
      USD
      NGN
      __typename
    }
    weightedAnnualInterestRate {
      USD
      NGN
      __typename
    }
    nextInterestPayout {
      amount
      currency
      expectedDate
      __typename
    }
    lastWithdrawalAmount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBalancesQueryVariables,
  APITypes.GetUserBalancesQuery
>;
export const sendOTPToken = /* GraphQL */ `query SendOTPToken($userInfo: UserInfo, $nameOfAction: String) {
  sendOTPToken(userInfo: $userInfo, nameOfAction: $nameOfAction) {
    status
    message
    tokenValid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SendOTPTokenQueryVariables,
  APITypes.SendOTPTokenQuery
>;
export const verifyCaptcha = /* GraphQL */ `query VerifyCaptcha($token: String!) {
  verifyCaptcha(token: $token)
}
` as GeneratedQuery<
  APITypes.VerifyCaptchaQueryVariables,
  APITypes.VerifyCaptchaQuery
>;
export const verifyOTPToken = /* GraphQL */ `query VerifyOTPToken($userInfo: UserInfo, $token: String) {
  verifyOTPToken(userInfo: $userInfo, token: $token) {
    status
    message
    tokenValid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VerifyOTPTokenQueryVariables,
  APITypes.VerifyOTPTokenQuery
>;
export const initiateWithdrawalRequest = /* GraphQL */ `query InitiateWithdrawalRequest(
  $userInfo: UserInfo
  $amount: Float
  $currency: String
) {
  initiateWithdrawalRequest(
    userInfo: $userInfo
    amount: $amount
    currency: $currency
  ) {
    message
    error
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InitiateWithdrawalRequestQueryVariables,
  APITypes.InitiateWithdrawalRequestQuery
>;
