/* eslint-disable @typescript-eslint/no-unused-vars */
import { API, Auth } from 'aws-amplify'
import { ChallengeResponse } from 'interfaces/authState'
import SuccessHandler from 'utils/SuccessHandler'
import { GraphQLResult } from '@aws-amplify/api'
import {
  EncodeTokenQuery,
  GetUserQuery,
  InitiateWithdrawalRequestQuery,
  RoutingTypes,
  SendOTPTokenQuery,
  UpdateUserInput,
  VerifyOTPTokenQuery,
} from 'API'
import { MixPanel } from 'services/mixpanel'
import store from 'redux/v2/store'
import { ErrorHandler } from 'helpers/handlers'
import { setCurrentUser, setLogout, setTwoFaSuccess } from 'redux/v2/authSlice'
import { getUserCustom } from 'graphql/custom-queries'
import {
  encodeToken,
  initiateWithdrawalRequest,
  sendOTPToken,
  verifyOTPToken,
} from 'graphql/queries'
import { BankAccountTypes, IAccountDetails } from 'interfaces/bank'
import { updateUser } from 'graphql/mutations'
import { CaptializeText } from 'utils/v2/textUtils'
import { addMinutes, getTime } from 'date-fns'

const createSimpleToken = async (
  customIdentifier: string,
  useCustomTokenValidity = false
) => {
  const twoMinsGrace = Math.floor(getTime(addMinutes(new Date(), 2)) / 1000)
  const tokenBody = {
    customIdentifier,
    email: customIdentifier,
    useCustomTokenValidity,
    exp: twoMinsGrace,
  }

  const tokenResult = (await API.graphql({
    query: encodeToken,
    variables: {
      value: JSON.stringify(tokenBody),
      secret: 'authorize',
    },
  })) as GraphQLResult<EncodeTokenQuery>

  const token = tokenResult?.data?.encodeToken
  return token
}

export const getUnAuthToken = async (customIdentifier: string) => {
  const token = await createSimpleToken(customIdentifier, true)
  return ['', token, token, token].join('Bearer.Access.')
}

export const getAuthToken = async (customIdentifier = '') => {
  const currentSession = await Auth.currentSession()

  const accessToken = currentSession.getAccessToken().getJwtToken()
  const idToken = currentSession.getIdToken().getJwtToken()

  const tokenArray = ['', accessToken, idToken]

  if (customIdentifier) {
    const customToken = await createSimpleToken(customIdentifier)
    customToken && tokenArray.push(customToken)
  }

  return tokenArray.join('Bearer.Access.')
}

export const login = async (email: string, password: string) => {
  const data = await Auth.signIn(email.trim(), password)

  if (data.challengeName === ChallengeResponse.CUSTOM_CHALLENGE) {
    SuccessHandler({
      message: 'Verification code sent successfully.',
    })
  }

  return data
}

export const getUserData = async (id: string) => {
  const authToken = await getUnAuthToken(id)
  const userData = (await API.graphql({
    query: getUserCustom,
    variables: { id },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    // authToken,
  })) as GraphQLResult<GetUserQuery>
  MixPanel.people.set({
    $first_name: `${userData.data?.getUser?.firstName}`,
    $last_name: `${userData.data?.getUser?.lastName}`,
    $email: `${userData.data?.getUser?.email}`,
  })
  return userData?.data?.getUser
}

export const getCurrentAuthenticatedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()

    const userData = await getUserData(user.attributes.sub)
    store.dispatch(
      setCurrentUser({
        attributes: userData || {},
        isAuthenticated: true,
      })
    )

    return user
  } catch (err) {
    return store.dispatch(
      setCurrentUser({
        attributes: {},
        isAuthenticated: false,
      })
    )
  }
}

export const confirmAuthentication = async (
  payload: { [key: string]: any },
  code: string
) => {
  try {
    await Auth.sendCustomChallengeAnswer(payload, code)

    const user = await getCurrentAuthenticatedUser()

    if (user.payload && !user.payload.isAuthenticated) {
      ErrorHandler({
        message: 'The verification code has expired, please resend a new one.',
      })
      return 'expired'
    } else {
      const userId = user.attributes.sub
      MixPanel.identify(userId as string)
      store.dispatch(
        setTwoFaSuccess({
          status: 'success',
        })
      )
    }
  } catch (err) {
    console.log('err', err)
  }
}

export const logout = async () => {
  await Auth.signOut()
  MixPanel.signout()
  return store.dispatch(setLogout())
}

export const sendOTPCode = async (
  userInfo: {
    id: string
    name: string
    email: string
  },
  nameOfAction?: string
) => {
  try {
    const authToken = await getAuthToken(userInfo.id)
    const tokenResponse = (await API.graphql({
      query: sendOTPToken,
      variables: {
        userInfo,
        nameOfAction,
      },
      // authToken,
      authMode: 'API_KEY',
    })) as GraphQLResult<SendOTPTokenQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}

export const verifyOTPCode = async (userInfo: { id: string }, code: string) => {
  try {
    const authToken = await getAuthToken(userInfo.id)
    const tokenResponse = (await API.graphql({
      query: verifyOTPToken,
      variables: {
        userInfo,
        token: code,
      },
      // authToken,
      authMode: 'API_KEY',
    })) as GraphQLResult<VerifyOTPTokenQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}

export const initiateWithdrawal = async (
  userInfo: {
    id: string
    name: string
    email: string
  },
  amount: number,
  currency: string
) => {
  try {
    const authToken = await getAuthToken(userInfo.id)
    const tokenResponse = (await API.graphql({
      query: initiateWithdrawalRequest,
      variables: {
        userInfo,
        amount,
        currency,
      },
      authMode: 'API_KEY',
      // authToken,
    })) as GraphQLResult<InitiateWithdrawalRequestQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}
export const updateUserBankAccountInfo = async (
  payload: IAccountDetails,
  accountType: BankAccountTypes,
  action: string
) => {
  const actionText = action === 'edit' ? 'Updated' : 'Created'

  try {
    const routingType = payload.routingType
    const bankAccountPayoutUSD = {
      accountName: payload.accountName,
      accountType: payload.accountType,
      accountNumber: payload.accountNumber,
      routingType,
      routingNumber: payload.routingNumber,
      achRoutingNumber:
        routingType === RoutingTypes.ACH ? payload.routingNumber : '',
      wireRoutingNumber:
        routingType === RoutingTypes.Wire ? payload.routingNumber : '',
      bankName: payload.bankName,
      iban: payload.ibanCode,
      swiftCode: payload.swiftCode?.trim(),
      country: payload.country,
      currency: 'USD',
      bankAddress: payload.bankAddress,
      bankCountry: payload.bankCountry,
      bankPostalCode: payload.bankPostalCode,
      recipientCountry: payload.recipientCountry,
      recipientAddress: payload.personalAddress,
      memo: payload.memo,
    }

    const bankAccountPayoutNGN = {
      accountName: payload.accountName,
      accountType: payload.accountType,
      accountNumber: payload.accountNumber,
      achRoutingNumber: payload.routingNumber,
      bankName: payload.bankName,
      iban: payload.ibanCode,
      country: payload.country,
      currency: 'NGN',
      bankAddress: payload.bankAddress,
      bankPostalCode: payload.bankPostalCode,
      recipientCountry: payload.recipientCountry,
      recipientAddress: payload.personalAddress,
      memo: payload.memo,
    }

    const user = await getCurrentAuthenticatedUser()
    const userId = user.attributes.sub

    let params = {}
    if (accountType === BankAccountTypes.USD) {
      params = { bankAccountPayoutUSD: bankAccountPayoutUSD }
    }

    if (accountType === BankAccountTypes.NGN) {
      params = { bankAccountPayoutNGN: bankAccountPayoutNGN }
    }

    const authToken = await getAuthToken(userId)
    const userData: any = (await API.graphql({
      query: updateUser,
      variables: { input: { id: userId, ...params } },
      // authToken,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateUserInput>

    SuccessHandler({
      message: `${accountType} Bank Account ${actionText} Successfully`,
    })
    store.dispatch(
      setCurrentUser({
        attributes: userData.data.updateUser || {},
        isAuthenticated: true,
      })
    )
    return userData?.data
  } catch (err: any) {
    console.log(err)
    if (err.data?.updateUser) {
      return store.dispatch(
        setCurrentUser({
          attributes: err.data?.updateUser || {},
          isAuthenticated: true,
        })
      )
    } else {
      if (err.message) {
        ErrorHandler(err)
      } else {
        ErrorHandler()
      }
    }
  }
}

export const deleteBankAccountInfo = async (accountType: BankAccountTypes) => {
  try {
    const user = await getCurrentAuthenticatedUser()
    const userId = user.attributes.sub

    let params = {}
    if (accountType === BankAccountTypes.USD) {
      params = { bankAccountPayoutUSD: null }
    }

    if (accountType === BankAccountTypes.NGN) {
      params = { bankAccountPayoutNGN: null }
    }

    const authToken = await getAuthToken(userId)
    const userData: any = (await API.graphql({
      query: updateUser,
      variables: { input: { id: userId, ...params } },
      // authToken,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateUserInput>

    SuccessHandler({
      message: `${accountType} Bank Account Deleted Successfully`,
    })
    store.dispatch(
      setCurrentUser({
        attributes: userData.data.updateUser || {},
        isAuthenticated: true,
      })
    )
    return userData?.data
  } catch (err: any) {
    console.log(err)
    if (err.data?.updateUser) {
      return store.dispatch(
        setCurrentUser({
          attributes: err.data?.updateUser || {},
          isAuthenticated: true,
        })
      )
    } else {
      if (err.message) {
        ErrorHandler(err)
      } else {
        ErrorHandler()
      }
    }
  }
}

export const sendBankAccountOTPCode = async (
  user: {
    id: string
    name: string
    email: string
  },
  currency: string,
  action: string
) => {
  const actionText = CaptializeText(action)
  const nameOfAction = `${actionText} ${currency} Bank Account`
  await sendOTPCode(user, nameOfAction)
}
