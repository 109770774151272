import React, { Suspense, useState } from 'react'
import { ReactComponent as ArrowRightSvg } from 'assets/Images/arrowRight.svg'
import { ReactComponent as ArrowLeftSvg } from 'assets/Images/arrowLeft.svg'
import flunaLogo from 'assets/Images/flunaLogoHD.png'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Navigate, Outlet } from 'react-router-dom'
import MenuLink from 'components/Links/MenuLink'
import SplashScreen from 'components/SplashScreen'
import { SideNavigation, SettingsNav } from 'constants/v2/Navigation'
import Navbar from '../navbar/NavBar'
import { useAppSelector } from 'redux/v2/hooks'
import { KycVerificationStatus, OnboardingStatus } from 'API'
import { baseRoute } from 'utils/helpers'
const DashboardLayout = () => {
  const secondaryRoute = ''
  const [opened, setOpened] = useState<boolean>(true)
  const { user } = useAppSelector((state) => state.auth)
  const { kycVerificationStatus } = user

  const isKycVerified =
    kycVerificationStatus === KycVerificationStatus.verified ||
    kycVerificationStatus === KycVerificationStatus.finished

  if (
    !user.onboardingStatus ||
    [OnboardingStatus.pending].includes(user.onboardingStatus)
  ) {
    return <Navigate to={`${baseRoute}/onboarding`} />
  }
  return (
    <>
      <Grid container>
        <Grid
          item
          md={opened ? 3 : 1}
          lg={opened ? 2 : 1}
          sx={{
            color: '#D5D7E3',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Grid
            sx={{
              backgroundColor: '#212437',
              height: '100vh',
              overflowX: 'hidden',
              position: 'fixed',
              zIndex: '100',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              webkitOverflowScrolling: 'touch',
              display: 'flex',
              flexDirection: 'column',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              width: {
                md: opened ? '25%' : '8.3%',
                lg: opened ? '16.6%' : '8.3%',
              },
            }}
          >
            <Grid
              mt={4}
              mb={opened ? 4 : 2}
              mx={opened ? 2 : 0}
              sx={{ paddingRight: '1rem' }}
              container
              direction={opened ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ maxWidth: '8rem', maxHeight: '2.7rem' }}>
                <img
                  alt="Fluna"
                  src={flunaLogo}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <IconButton
                size="large"
                edge="start"
                data-testid="collapse"
                aria-label="collapse"
                onClick={() => setOpened(!opened)}
                sx={{ color: '#171721' }}
              >
                {opened ? <ArrowLeftSvg /> : <ArrowRightSvg />}
              </IconButton>
            </Grid>
            <Box
              sx={{
                height: '100%',
                padding: '1rem 0rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  color: '#171721',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  flex: 1,
                }}
              >
                {SideNavigation.map((page) => (
                  <MenuLink
                    key={page.name}
                    page={page}
                    opened={opened}
                    allowComing
                    allowNotif
                    disabled={
                      !isKycVerified &&
                      !(
                        page.name === 'Dashboard' ||
                        page.name === 'Help' ||
                        page.name === 'Transaction Activity'
                      )
                    }
                  />
                ))}
              </Box>
              <Box
                sx={{
                  mt: 'auto',
                  display: { xs: 'none', md: 'flex' },
                  color: '#171721',
                  flexDirection: 'column',
                }}
              >
                {SettingsNav.map((page) => (
                  <MenuLink key={page.name} page={page} opened={opened} />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Suspense fallback={<SplashScreen />}>
          <Grid
            sx={{
              width: '100vw',
              display: 'block',
              paddingLeft: {
                md: opened && secondaryRoute ? '16%' : '0',
                lg: opened && secondaryRoute ? '16%' : '0',
              },
            }}
            item
            md={opened ? 9 : 11}
            lg={opened ? 10 : 11}
          >
            <Navbar />
            <Box
              sx={{
                width: '100%',
                display: 'block',
                margin: '0 auto',
                zIndex: '0',
                paddingLeft: {
                  md: !opened && secondaryRoute ? '16%' : '0',
                  lg: !opened && secondaryRoute ? '16%' : '0',
                },
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Suspense>
      </Grid>
    </>
  )
}
export default DashboardLayout
