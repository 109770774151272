/* eslint-disable */

import { Auth } from 'aws-amplify'
import { Quarter } from 'interfaces/bank'
import { Investment } from 'interfaces/investments'
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'
import { countries } from 'mocks/countries'
import { MutableRefObject } from 'react'
import store from 'redux/v2/store'
import { Investment as apiInvestment } from '../API'
import {
  MAX_DEPOSIT_NGN,
  MAX_DEPOSIT_USD,
  MAX_INVESTMENT_NGN,
  MAX_INVESTMENT_USD,
  MIN_DEPOSIT_NGN,
  MIN_DEPOSIT_USD,
  MIN_INVESTMENT_NGN,
  MIN_INVESTMENT_USD,
} from '../data/global'
import { IUser } from '../interfaces/User'
import errorHandler from './ErrorHandler'
import { format, parse, parseISO } from 'date-fns'

export const baseRoute = ''

interface IValidatePassword {
  [key: string]: boolean
}

export const parseRange = (rangeStr: string) => {
  const splitRange = rangeStr.split(' - ')

  if (splitRange.length > 1) {
    const [minStr, maxStr] = splitRange.map((str) =>
      str.trim().replace(/[\$,]/g, '')
    )
    const min = parseInt(minStr, 10)
    const max = parseInt(maxStr, 10)
    return { min, max }
  } else {
    const value = rangeStr.trim().replace(/[\$,<>]/g, '')

    if (rangeStr.indexOf('<') !== -1) {
      return {
        min: 0,
        max: parseInt(value, 10) - 1,
      }
    } else if (rangeStr.indexOf('>') !== -1) {
      return {
        min: parseInt(value, 10) + 1,
        max: 100000000000000,
      }
    } else {
      return {
        min: 0,
        max: 0,
      }
    }
  }
}

export const handleErrorOnInput = (
  ref: MutableRefObject<HTMLInputElement | null>,
  querySelector?: string
) => {
  return (action?: 'add' | 'remove') => {
    if (ref.current) {
      const element = querySelector
        ? ref.current.querySelector(querySelector)
        : ref.current

      if (element) {
        const elementToStyle = element as HTMLElement

        if (action === 'remove') elementToStyle.classList.remove('Mui-error')
        else if (action === 'add') elementToStyle.classList.add('Mui-error')
      }
    }
  }
}

export const parsePhoneNumber = (
  value: string,
  dialCode: string,
  countryCode: string
) => {
  try {
    const parsedNumber = parsePhoneNumberFromString(value, {
      defaultCallingCode: dialCode.replace(/\+/g, ''),
    })
    return parsedNumber
  } catch (err) {
    const parsedNumber = parsePhoneNumberFromString(value, {
      defaultCountry: countryCode as CountryCode,
    })
    return parsedNumber
  }
}

export const formatPhoneNumber = (
  value: string,
  dialCode: string,
  countryCode: string,
  cleanResult = false
) => {
  let res = ''
  const parsedNumber = parsePhoneNumber(value, dialCode, countryCode)

  if (parsedNumber) {
    const internationalFormat = parsedNumber.formatInternational()
    const escapedDialCode = dialCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const regex = new RegExp(escapedDialCode, 'g')
    const result = internationalFormat.replace(regex, '')
    res = result.trim()
  } else {
    if (cleanResult) res = value.replace(/ /g, '')
    res = value
  }

  return {
    parsedNumber,
    result: res,
  }
}

export const changeOTPState = (
  container: HTMLDivElement | null,
  disable: boolean
) => {
  if (container) {
    const inputs = container.querySelectorAll('input')

    inputs.forEach((input) => {
      if (disable) {
        input.disabled = true
      } else {
        input.disabled = false
      }
    })
  }
}

export const clearInputs = (container: HTMLDivElement | null) => {
  if (container) {
    const inputs = container.querySelectorAll('input')

    inputs.forEach((input) => {
      input.innerHTML = ''
      input.value = ''
    })
  }
}

export const handleErrorState = (container: HTMLDivElement | null) => {
  return (error = false) => {
    if (container) {
      const inputs = container.querySelectorAll('input')

      inputs.forEach((input) => {
        const value = input.value

        if (value) {
          input.style.border = '1px solid rgba(187, 190, 194, 1)'
        }

        if (error) {
          input.style.border = '1.5px solid #A73636'
        }
      })
    }
  }
}

export const insertHrBetweenInputs = (container: HTMLDivElement) => {
  const inputs = container.querySelectorAll('input')

  if (container) {
    const codeInput = container.querySelector(
      '.react-code-input'
    ) as HTMLElement

    if (codeInput) {
      codeInput.style.display = 'flex'
      codeInput.style.alignItems = 'center'
    }
  }

  inputs.forEach((input, index) => {
    input.classList.add('otp-input')

    if (index < inputs.length - 1) {
      const nextSibling = input.nextSibling as HTMLElement

      if (nextSibling?.tagName !== 'HR') {
        const hr = document.createElement('hr')
        hr.style.border = '1.5px solid rgba(233, 234, 239, 1)'
        hr.style.width = '15px'
        input.parentNode?.insertBefore(hr, nextSibling)
      }
    }

    input.addEventListener('keyup', () => {
      const text = Array(input.value.length).fill('*').join('')
      input.innerHTML = text
    })
  })
}

export const maskEmail = (email?: string): string => {
  if (email) {
    const atIndex = email.indexOf('@')
    const namePart = email.slice(0, atIndex)
    const domainPart = email.slice(atIndex)

    if (atIndex > 4) {
      return namePart.slice(0, -4) + '****' + domainPart
    } else {
      return '*'.repeat(namePart.length) + domainPart
    }
  }

  return ''
}

export const maskText = (text: string): string => {
  if (!text) {
    return ''
  }
  const textLength = text.length
  return '****' + text.slice(textLength - 4)
}

export function validatePasswordInput<T>(value: T): IValidatePassword {
  const isMinLength = typeof value === 'string' && value.length >= 8

  const hasUniqueChar = /[-=+_{}\\:”';<>,/^()!@#$%^&*.?"]/.test(String(value))

  const isUpperCase = /[A-Z]/.test(String(value))

  return { isMinLength, isUpperCase, hasUniqueChar }
}

export const handleCopyToClipboard = (textToCopy: string) => {
  navigator.clipboard.writeText(textToCopy)
}

export const formatTaxID = (value: any) => {
  let inputValue = value?.split('-').join('')

  if (inputValue?.length > 0) {
    inputValue = inputValue.match(new RegExp('.{1,4}', 'g')).join('-')
  }

  // return value = inputValue
  return value
}

export const formatAmount = (amount: number) => {
  const formattedAmount = amount.toFixed(2)
  const parts = formattedAmount.split('.')
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const decimalPart = parts[1]
  return `${integerPart}.${decimalPart}`
}

export const getValueFromPath = (object: any, path: string) => {
  return path.split('.').reduce((result, key) => result?.[key], object)
}

export const calculateInterest = (
  principal: number,
  annualInterestRate: number,
  duration: number
) => {
  return Number(
    Number(principal * (annualInterestRate * 0.01)) * ((duration * 30) / 365)
  )
    .toFixed(2)
    .toString()
}

//500 * (0.1 * 29.5890410959)
export const calculateTotalBalance = (investment_: Investment) => {
  let total = 0
  const principal = investment_.principleAmount || 0
  const paymentsTotal =
    investment_.payments?.items
      .filter(
        (payment) =>
          payment?.paymentCategory === 'Interest' && payment.Status === 'Paid'
      )
      .reduce((total, payment: any) => total + payment?.amount, 0) || 0
  total = principal + paymentsTotal
  return total
}

export function sentenceCase(str: string) {
  return str.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase())
}

export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const generateUniqueRef = (accountId: string, clientId: string) => {
  const a: string = accountId
  const b: string = clientId
  const ref =
    a.slice(a.length - 5, a.length - 1) +
    '-' +
    b.slice(b.length - 5, b.length - 1)
  return ref
}

export const getCountryCode = (country: string) => {
  let code = null
  const list = countries
  const country_ = list.find((x) => {
    return x.label === country
  })

  if (country_ === undefined || country === null) {
    code = 'US'
    return code
  }
  code = country_.code
  return code
}

export const filterStatesWithoutIsland = (states: any[]) => {
  return states.filter((state) => {
    return state.label && !state.label.includes('Island')
  })
}

export const filterStatesWithoutIslandV2 = (states: any[]) => {
  return states.filter((state) => {
    return state.name && !state.name.includes('Island')
  })
}

export const UppercaseTransform = (value: string | number) => {
  if (value && typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  } else {
    return value
  }
}

export const stripSpaces = (item: string) => {
  return item.replace(/\s+/g, '')
}

export const stringToNumberStr = (item: string) => {
  return item?.replace(/,/g, '')
}

export const formatNumber = (value: string) => {
  const parts = value.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const removeLeadingZeros = (value: string) => {
  if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
    return value.replace(/^0+/, '')
  }
  return value
}

export const getDaysLeftToFund = (maxDays: number, date: string) => {
  let createdAt: Date

  // Check if the date is in ISO format
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/

  if (isoRegex.test(date)) {
    createdAt = parseISO(date)
  } else {
    createdAt = parse(date, 'MMM d, yyyy', new Date())
  }
  const today = new Date().getTime()
  const difference = today - createdAt.getTime()
  const daysDifference = Math.floor(difference / (1000 * 3600 * 24))
  return maxDays - daysDifference
}

export const getCurrencySymbol = (currency: string) => {
  let symbol: string | null = null

  if (currency.toLowerCase() === 'usd') {
    symbol = '$'
  }

  if (currency.toLowerCase() === 'ngn') {
    symbol = '₦'
  }

  if (currency.toLowerCase() === 'ghs') {
    symbol = '₵'
  }

  return symbol
}

export const getEndDate = (startDate: Date, tenorDays: number) => {
  return new Date(startDate.setDate(startDate.getDate() + tenorDays))
}

export const downloadBlob = (blob: Blob, name: string, ext: string) => {
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = `${name}.${ext}`
  a.click()
  window.URL.revokeObjectURL(url)
}

export const formatDate = (date: Date): string => {
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })

  let suffix = 'th'
  if (day < 11 || day > 13) {
    switch (day % 10) {
      case 1:
        suffix = 'st'
        break
      case 2:
        suffix = 'nd'
        break
      case 3:
        suffix = 'rd'
        break
    }
  }

  return `${month} ${day}${suffix}, ${date.getFullYear()}`
}

export const getPastQuarters = (
  startDateString: string,
  endDateString: string
): Quarter[] => {
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
  const endMonths = [3, 6, 9, 12]
  const quartersArray: Quarter[] = []

  const currentDate = new Date(endDateString)
  const cutoffStartDate = startDateString
    ? new Date(startDateString)
    : new Date(2023, 3, 1)

  const startYear = cutoffStartDate.getFullYear()
  const endYear = currentDate.getFullYear()

  for (let year = startYear; year <= endYear; year++) {
    for (let index = 0; index < quarters.length; index++) {
      const quarter = quarters[index]
      const quarterEndMonth = endMonths[index]
      const quarterStartMonth = quarterEndMonth - 2
      const startDate = new Date(year, quarterStartMonth - 1, 1)
      const endDate = new Date(
        year,
        quarterEndMonth - 1,
        new Date(year, quarterEndMonth, 0).getDate()
      )

      if (endDate < cutoffStartDate || startDate > currentDate) continue

      quartersArray.push({
        label: `${quarter} ${year}`,
        period: `${formatDate(startDate)} - ${formatDate(endDate)}`,
        startDate: startDate,
        endDate: endDate,
      })
    }
  }

  quartersArray.sort((a, b) => b.endDate.getTime() - a.endDate.getTime())
  return quartersArray
}

export const noUserHelper = async (error: Error) => {
  if (error.message === 'No current user') {
    errorHandler({
      message: 'Oops! You are currently logged out',
    })
    await Auth.signOut()
    window.location.reload()
  }
}

export const isDevelopment = () => {
  const isDev = process.env.REACT_APP_NODE_ENV === 'development'
  console.log('isDevelopment', isDev)
  return isDev
}

export const convertFormikValuesToArray = (values: any) => {
  return Object.keys(values).map((key) => ({
    label: key,
    value: values[key],
  }))
}

export const extractAccountType = (accountType: string) => {
  if (accountType.includes('Personal')) {
    return accountType.replace('Personal', 'Personal ').split(' ')[1]
  } else if (accountType.includes('Business')) {
    return accountType.replace('Business', 'Business ').split(' ')[1]
  }
  return accountType
}

export const sortArrayByField = (
  arr: any[],
  sortField: string,
  order: 'asc' | 'desc'
): any[] => {
  return [...arr].sort((a: any, b: any) => {
    const dateA: number = new Date(a[sortField]).getTime()
    const dateB: number = new Date(b[sortField]).getTime()
    return order === 'desc' ? dateB - dateA : dateA - dateB
  })
}

export const formatTimestampToDate = (
  timestamp: string | null | undefined
): string => {
  return format(parseISO(timestamp as string), 'do MMM, yyyy')
}

export const getCurrencyLimits = (
  currency: 'NGN' | 'USD',
  user?: Partial<IUser>
) => {
  const userMinimumInvestmentUSD = user?.minimumInvestment || 0

  // Determine the minimum investment for USD
  const minInvestUSD = Math.max(MIN_INVESTMENT_USD, userMinimumInvestmentUSD)

  // Define currency limits
  const currencyLimits = {
    NGN: {
      minDeposit: MIN_INVESTMENT_NGN,
      maxDeposit: MAX_INVESTMENT_NGN,
    },
    USD: {
      minDeposit: minInvestUSD,
      maxDeposit: MAX_INVESTMENT_USD,
    },
  }

  const foundCurrency = currencyLimits[currency]

  // Return the found currency limits or default values if not found
  return foundCurrency
    ? {
        ...foundCurrency,
        currencySymbol: getCurrencySymbol(currency),
      }
    : {
        minDeposit: 0,
        maxDeposit: 0,
        currencySymbol: '',
      }
}

export const getCurrencyFundingLimits = (currency: string) => {
  const _currency = currency.toLowerCase()
  const maxDeposit = _currency === 'ngn' ? MAX_DEPOSIT_NGN : MAX_DEPOSIT_USD
  const minDeposit = _currency === 'ngn' ? MIN_DEPOSIT_NGN : MIN_DEPOSIT_USD
  const currencySymbol = getCurrencySymbol(currency || '') || currency

  return {
    maxDeposit,
    minDeposit,
    currencySymbol,
  }
}

export const getWalletWithdrawalLimit = (currency: string) => {
  const { balances } = store.getState().balances
  const _currency = currency.toLowerCase()
  const maxWithdrawal =
    _currency === 'ngn'
      ? balances.walletBalance.NGN
      : balances.walletBalance.USD

  const minWithdrawal = 1
  const currencySymbol = getCurrencySymbol(currency || '') || currency
  return {
    maxWithdrawal,
    minWithdrawal,
    currencySymbol,
  }
}
export const removeTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename)
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {}
    for (const key in obj) {
      if (key !== '__typename') {
        newObj[key] = removeTypename(obj[key])
      }
    }
    return newObj
  }
  return obj
}

export const sanitizeInvestmentInput = (obj: any): Partial<apiInvestment> => {
  console.log(obj)
  const {
    id,
    startDate,
    endDate,
    interestPayoutOption,
    annualInterestRate,
    renewFlag,
    renewOption,
    currency,
    principleAmount,
    InterestCycleDays,
    tenorDays,
    expectedTotalInterest,
    expectedInterestPerInterestCycle,
    expectedNumberOfInterestCycles,
    Status,
    funded,
    userID,
    investmentStep,
  } = obj

  // Handle investmentAgreement which might be null
  const investmentAgreement = obj.investmentAgreement ?? {
    __typename: 'InvestmentAgreement',
    signature: '',
    signed: false,
    signedAgreementUrl: '',
    signedAt: new Date().toISOString(),
  }

  return {
    id,
    startDate,
    endDate,
    interestPayoutOption,
    annualInterestRate,
    renewFlag,
    renewOption,
    currency,
    principleAmount,
    InterestCycleDays,
    tenorDays,
    expectedTotalInterest,
    expectedInterestPerInterestCycle,
    expectedNumberOfInterestCycles,
    funded,
    userID,
    investmentStep,
    investmentAgreement,
  }
}

export const showNgnDetails = (user: Partial<IUser>) => {
  return user.wallets?.NGN !== null
}

export const generateRandomId: () => string = () => {
  const idValues = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ]

  const randomUUID = () => {
    let id = ''
    for (let j = 0; j < 4; j += 1) {
      const randomPartIdx = parseInt((Math.random() * 36).toFixed(0), 10) % 36
      id += idValues[randomPartIdx]
    }
    return id
  }

  const stringedId = randomUUID()
  const uniqueId = 'ID' + stringedId

  return uniqueId
}
