export const camelCaseToNormal = (initText: string) => {
  const replacedText = initText.replace(/([A-Z]+)/g, ' $1')
  const formattedText =
    replacedText.charAt(0).toUpperCase() + replacedText.slice(1)
  return formattedText
}

export const CaptializeText = (text: string) => {
  if (text) {
    const result = text.charAt(0).toUpperCase() + text.slice(1) || text

    return result
  }
  return ''
}

export const escapeHtml = (
  value: string | number | React.ReactElement | undefined
): string | number | React.ReactElement | undefined => {
  if (typeof value === 'string') {
    return value
      .replace(/</g, '')
      .replace(/>/g, '')
      .replace(/"/g, '')
      .replace(/'/g, '')
  }

  // Return the value as is if it's not a string
  return value
}
