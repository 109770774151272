/* eslint-disable @typescript-eslint/no-unused-vars */

import { OnboardingStatus } from 'API'
import React from 'react'
import { useState } from 'react'

import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/v2/hooks'
import { baseRoute } from 'utils/helpers'

const routes = {
  login: `${baseRoute}/login`,
  dashboard: `${baseRoute}/dashboard`,
  onboarding: `${baseRoute}/onboarding`,
}

const AuthGuardStatus = (props: { children: React.ReactElement }) => {
  const { children } = props
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  )

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }

    return <Navigate to={routes.login} />
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null)

    if (!user.onboardingStatus) {
      return <Navigate to={routes.onboarding} />
    }

    return <Navigate to={location.pathname} />
  }

  return <>{children}</>
}

export default AuthGuardStatus
